<template>
  <b-modal
    id="modal-invoice"
    ref="refModal"
    :title="t('Hóa đơn')"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    scrollable
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        v-if="itemLocal"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <div
          v-if="
            itemLocal && itemLocal.approve && itemLocal.id && itemLocal.id > 0
          "
        >
          <b-alert show variant="warning">
            <div class="alert-body">
              <feather-icon class="mr-25" icon="AlertTriangleIcon" />
              <span class="ml-25"
                >Thông báo phí đã duyệt nên chỉ có thể sửa các thông tin về kỳ
                thanh toán, hạn thanh toán, ngày thanh toán.<br /><br /><span
                  v-if="itemLocal.school"
                  >{{ t("Cơ sở") }}: {{ itemLocal.school.name }}</span
                ><br /><span v-if="itemLocal.class"
                  >{{ t("Lớp học") }}: {{ itemLocal.class.name }}</span
                ><span v-if="itemLocal.student"
                  ><br />{{ t("Học sinh") }}: {{ itemLocal.student.name }}</span
                ></span
              >
            </div>
          </b-alert>
          <b-row />
          <b-row>
            <b-col md="4">
              <select-month
                v-model="itemLocal.month"
                required
                :default-value="itemLocal.month"
                label="Kỳ thanh toán"
              />
            </b-col>

            <b-col md="4">
              <select-general-data
                :general-data-type="'paymentPeriod'"
                v-model="itemLocal.paymentPeriod"
                required
                :default-value="itemLocal.paymentPeriod"
                label="Lần TT"
                placeholder="Lần TT"
              />
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="validationContext"
                name="Hạn thanh toán"
                rules="required"
              >
                <select-date
                  v-model="itemLocal.dueDate"
                  required
                  :default-value="itemLocal.dueDate"
                  label="Hạn thanh toán"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <b-row>
            <b-col cols="12" class="my-1">
              <div
                class="d-flex justify-space-between font-small-4 font-weight-bolder text-primary text-uppercase"
              >
                1. Thông tin chung
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="school"
                rules="required"
              >
                <select-school
                  v-model="itemLocal.school"
                  :filter-active="true"
                  required
                  :enable="!(itemLocal.id && itemLocal.id > 0)"
                  :default-value="itemLocal.school"
                  :label="t('Cơ sở')"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="class"
                rules="required"
              >
                <select-class
                  v-model="itemLocal.class"
                  :filter-active="true"
                  required
                  :enable="!(itemLocal.id && itemLocal.id > 0)"
                  :school="itemLocal.school"
                  :default-value="itemLocal.class"
                  :label="t('Lớp học')"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="student"
                rules="required"
              >
                <select-student
                  v-model="itemLocal.student"
                  :filter-active="true"
                  required
                  :enable="!(itemLocal.id && itemLocal.id > 0)"
                  :class-object="itemLocal.class"
                  :default-value="itemLocal.student"
                  :label="t('Học sinh')"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <select-month
                v-model="itemLocal.month"
                required
                :default-value="itemLocal.month"
                label="Kỳ thanh toán"
              />
            </b-col>
            <b-col>
              <select-general-data
                :general-data-type="'paymentPeriod'"
                placeholder="Lần TT"
                v-model="itemLocal.paymentPeriod"
                required
                :default-value="itemLocal.paymentPeriod"
                label="Lần TT"
              />
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="Hạn thanh toán"
                rules="required"
              >
                <select-date
                  v-model="itemLocal.dueDate"
                  required
                  :default-value="itemLocal.dueDate"
                  label="Hạn thanh toán"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
          </b-row>

          <hr class="mt-4" />
          <b-row>
            <b-col cols="12" class="my-1">
              <div class="d-flex justify-space-between">
                <div
                  class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary"
                >
                  2. Các khoản phải thu
                </div>
                <b-button
                  v-b-modal.modal-list-service
                  variant="primary"
                  class="btn-icon ml-auto"
                  size="sm"
                >
                  <feather-icon icon="PlusSquareIcon" />
                </b-button>
              </div>
            </b-col>
            <b-col
              v-if="!(itemLocal.id && itemLocal.isLiquidInvoice)"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="Dịch vụ"
                rules="required"
              >
                <fee-table
                  v-model="itemLocal.incomeItems"
                  :student="itemLocal.student"
                  :invoice-id="itemLocal.id"
                  :rows="itemLocal.incomeItems"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
          </b-row>

          <hr class="mt-2" />
          <b-row>
            <b-col cols="12" class="my-1">
              <div class="d-flex justify-space-between">
                <div
                  class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary"
                >
                  3. Các khoản giảm trừ
                </div>
                <b-button
                  v-b-modal.modal-list-service
                  variant="primary"
                  class="btn-icon ml-auto"
                  size="sm"
                >
                  <feather-icon icon="PlusSquareIcon" />
                </b-button>
              </div>
            </b-col>
            <b-col
              v-if="!(itemLocal.id && itemLocal.isLiquidInvoice)"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="Dịch vụ"
                rules=""
              >
                <fee-table
                  v-model="itemLocal.discountItems"
                  :invoice-id="itemLocal.id"
                  :rows="itemLocal.discountItems"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
          </b-row>

          <hr class="mt-4" />
          <!-- <span>Tổng tiền: {{ Number(total).toLocaleString() }}</span> -->
          <b-row>
            <b-col>
              <b-form-group label="Tạm tính">
                <b-form-input
                  id="subtotal"
                  :value="Number(total).toLocaleString()"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Tạm tính sau thuế">
                <b-form-input
                  id="subtotal"
                  :value="Number(totalWithTax).toLocaleString()"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Tiền thừa">
                <b-form-input
                  id="prepaid"
                  v-model="itemLocal.prepaid"
                  :value="Number(prepaid).toLocaleString()"
                  :readonly="!enableEditPrepaid"
                  @click="setEnablePrepaidEdit"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Phải thanh toán">
                <b-form-input
                  id="total"
                  :value="Number(remain).toLocaleString()"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group label="Ghi chú">
                <b-form-textarea
                  id="note"
                  v-model="itemLocal.note"
                  placeholder="Ghi chú"
                  rows="3"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-form>
    </validation-observer>

    <select-multiple-fee-modal
      :fees-selected="[
        ...itemLocal.incomeItems.map((object) => object.feeObject),
        ...itemLocal.discountItems.map((object) => object.feeObject),
      ]"
      @selectService="onAddFee"
    />
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  VBTooltip,
  VBModal,
  BAlert,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, maxValue } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import SelectDate from "@/views/components/SelectDate.vue";
import SelectMonth from "@/views/components/SelectMonth.vue";
import SelectClass from "@/views/components/SelectClass.vue";
import useInvoiceModal from "./useInvoiceModal";
import SelectSchool from "../../../components/SelectSchool.vue";
import SelectStudent from "../../../components/SelectStudent.vue";
import FeeTable from "./FeeTable.vue";
import SelectMultipleFeeModal from "../../../fee/select/SelectMultipleFeeModal.vue";
import SelectGeneralData from "@/views/components/SelectGeneralData.vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BAlert,
    SelectDate,
    SelectMonth,
    SelectSchool,
    SelectClass,
    SelectStudent,
    FeeTable,
    SelectMultipleFeeModal,
    SelectGeneralData,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      maxValue,
    };
  },
  setup(props, { emit }) {
    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation();

    const {
      refModal,
      refForm,
      itemLocal,
      total,
      totalWithTax,
      isSubmitting,
      prepaid,
      remain,
      removeIncomeItem,
      removeDiscountItem,
      addIncomeItem,
      addDiscountItem,
      resetItemLocal,
      resetModal,
      onSubmit,
      onAddFee,
      enableEditPrepaid,
      setEnablePrepaidEdit,
    } = useInvoiceModal(props, emit, refFormObserver);

    const { t } = useI18nUtils();

    return {
      refModal,
      refForm,
      itemLocal,
      total,
      totalWithTax,
      isSubmitting,
      prepaid,
      remain,
      removeIncomeItem,
      removeDiscountItem,
      addIncomeItem,
      addDiscountItem,
      resetItemLocal,
      resetModal,
      onSubmit,
      onAddFee,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      enableEditPrepaid,
      setEnablePrepaidEdit,
      t,
    };
  },
};
</script>
