<template>
  <b-form-group :label="label" :state="state">
    <template v-if="label" v-slot:label>
      {{ label }}
      <span v-if="required || students.length > 0" class="text-danger">
        (*)
      </span>
    </template>
    <v-select
      id="student"
      v-model="student"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="name"
      :options="students"
      :disabled="classObject == null || !enable"
      :placeholder="t('Học sinh')"
      @input="studentChanged"
      @open="onOpen"
    >
      <!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #no-options="{ search, searching, loading }">
        Không có dữ liệu
      </template>
    </v-select>
    <small class="text-danger">{{ error }}</small>
  </b-form-group>
</template>

<script>
import { BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import useJwt from "@/auth/jwt/useJwt";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    classObject: {
      type: Object,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    enable: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    filterActive: {
      type: Boolean,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      student: this.defaultValue,
      students: [],
    };
  },

  watch: {
    classObject(newVal) {
      this.student = null;
      this.studentChanged();
      if (newVal) {
        this.fetchStudents();
      } else {
        this.students = [];
      }
    },
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
  methods: {
    studentChanged() {
      this.$emit("input", this.student);
    },
    onOpen() {
      this.fetchStudents();
    },
    fetchStudents() {
      const filter = { classId: this.classObject.id };
      if (this.filterActive) {
        filter.active = this.filterActive;
      }
      useJwt
        .getStudents({ filter, sort: { field: "name", type: "asc" } })
        .then((res) => {
          this.students = res.data.items;
          this.$emit("listStudents", res.data.items);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/vue-select.scss";
</style>
