import { ref, computed } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { parseDateToString } from '@/auth/utils';
import moment from 'moment';
import invoiceStoreModule from '../invoiceStoreModule';

export default function useInvoicePaymentModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'invoice';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, invoiceStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const blankData = {
    amount: 0,
    note: '',
    issueDate: moment(new Date()).toDate(),
    payer: null,
  };
  const itemLocal = ref(blankData);

  const resetItemLocal = () => {
    itemLocal.value = { ...blankData, amount: props.item.remain, payer: props.item.student.name };
    isSubmitting.value = false;
  };
  const incomeText = computed(() => props.item.remain < 0 ? 'chi' : 'thu')
  const isExpense = computed(() => props.item.remain < 0 ? true : false)

  const resetModal = () => {
    resetItemLocal();
  };

  const openModal = () => {
    resetItemLocal();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      amount: itemLocal.value.amount,
      note: itemLocal.value.note,
      payer: itemLocal.value.payer,
      invoiceId: props.item.id,
      tingeeId: itemLocal.value.tingee ? itemLocal.value.tingee.id : null,
      isExpense: isExpense.value
    };

    data.issueDate = parseDateToString(itemLocal.value.issueDate);

    isSubmitting.value = true;

    store
      .dispatch('invoice/createInvoicePayment', data)
      .then(response => { emit('on-item-created', response.data); })
      .then(() => {
        refModal.value.toggle('#toggle-btn');
      })
      .then(() => {
        emit('refetch-data');
      })
      .then(() => {
        toastification.showToastCreateSuccess();
        isSubmitting.value = false;
      })
      .catch(error => {
        toastification.showToastError(error, refForm.value);
        isSubmitting.value = false;
      });
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    openModal,
    incomeText
  };
}
