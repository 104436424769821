<template>
  <b-card no-body>
    <b-card-body class="">
      <!-- <div class="d-flex justify-content-between align-items-center">
        <div class="truncate">
          <h3 :class="`mb-25 font-weight-bolder text-${color}`">
            {{ value }}
          </h3>
          <span :class="`text-${color}`">{{ title }}</span>
        </div>
        <b-avatar :variant="`light-${color}`" size="45">
          <feather-icon size="21" :icon="icon" />
        </b-avatar>
      </div> -->
      <div class="d-flex justify-content-between align-items-center">
        <span :class="`text-${color}`">{{ title }}:</span>
        <h3 :class="`my-25 font-weight-bolder text-${color} text-align-right`">
          {{ value }}
        </h3>
      </div>
      <div
        v-if="detailValue && showDetail"
        class="d-flex justify-content-between align-items-center"
      >
        <span :class="`text-secondary`">{{ detailTitle }}:</span>
        <h4 :class="`my-25 font-weight-bolder text-secondary text-align-right`">
          {{ detailValue }}
        </h4>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BAvatar } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    value: {
      type: [Number, String],
      required: true,
    },
    detailValue: {
      type: [Number, String],
      required: false,
    },
    title: {
      type: String,
      default: "",
    },
    detailTitle: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    titleColor: {
      type: String,
      default: "primary",
    },
    showDetail: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
